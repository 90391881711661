<template lang="pug">
include ../../../configs/template
div
  <h4 class="text-left">{{ $t('priceETI') }}</h4>
  div
    +radio-group('typePrice', 'items')(row @change="handleClickTypeDocuments")
  v-tabs(
    show-arrows
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline")
    v-tabs-slider(color="primary")
    div.d-flex.align-items-center(v-for="tab in tabs" :key="tab.id" )
      v-tab(:to="tab.link" v-if="tab.checkAccess()").p-2 {{$t(tab.label)}}
  router-view
</template>

<script>
import { mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  data () {
    return {
      typePrice: null,
      tabs: [
        { id: 1, label: 'priceETI', link: { name: 'backoffice-offline-rate', params: { typePrice: 'Offline' } }, checkAccess: () => checkAccess('menuItem-priceEtiCourse') && this.typePrice === '1' },
        { id: 2, label: 'coursePriceETI', link: { name: 'backoffice-offline-price-course', params: { typePrice: 'Offline' } }, checkAccess: () => checkAccess('menuItem-pricePacket') && this.typePrice === '1' },
        { id: 3, label: 'priceETI', link: { name: 'backoffice-online-rate', params: { typePrice: 'Online' } }, checkAccess: () => checkAccess('menuItem-priceEtiCourse') && this.typePrice === '2' },
        { id: 4, label: 'coursePriceETI', link: { name: 'backoffice-online-price-course', params: { typePrice: 'Online' } }, checkAccess: () => checkAccess('menuItem-pricePacket') && this.typePrice === '2' }
      ]
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.directory.listTypeExam
    })
  },
  methods: {
    handleClickTypeDocuments (item) {
      let { fullPath } = this.$route
      if (item === '1') {
        this.$router.replace({ params: 'Offline' })
        fullPath = fullPath.replace('online', 'offline')
        this.$router.push(fullPath)
      } else if (item === '2') {
        this.$router.replace({ params: 'Online' })
        fullPath = fullPath.replace('offline', 'online')
        this.$router.push(fullPath)
      }
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'back-office-price', access: checkAccess('menuItem-backOffice') })
  },
  created () {
    if (this.$route.name.includes('offline')) {
      this.typePrice = '1'
      !this.$route.params.typePrice && this.$router.replace({ params: { typePrice: 'Offline' } })
    } else {
      this.typePrice = '2'
      !this.$route.params.typePrice && this.$router.replace({ params: { typePrice: 'Online' } })
    }
  }
}
</script>
